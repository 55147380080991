// @ts-nocheck
import { Veriff } from '@veriff/js-sdk';
import { useEffect, useState } from 'react';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import PoweredBy from './PoweredBy';

const PageBody = () => {
  const [veriffSubmitted, setVeriffSubmitted] = useState(false);
  useEffect(() => {
    const veriff = Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey: '1081b190-f3ef-4ca4-a5a0-5d4a1437cc76',
      parentId: 'veriff-root',
      onSession: function (err, response) {
        createVeriffFrame({
          url: response.verification.url,
          onEvent: (msg) => {
            switch (msg) {
              case MESSAGES.CANCELED:
                //
                break;
              case MESSAGES.STARTED:
                //
                break;
              case MESSAGES.FINISHED:
                setVeriffSubmitted(true);
                break;
            }
          },
        });
      },
    });
    veriff.setParams({
      vendorData: ' ',
    });
    veriff.mount();
  }, []);

  return (
    <section className={'flex-1 flex flex-col items-center py-6 lg:p-12'}>
      {!veriffSubmitted ? (
        <>
          <div className="text-primary-v1 text-lg lg:text-2xl font-medium pb-4 lg:pb-8">
            Veriff Identification for BuyProperly
          </div>
          <div id="veriff-root"></div>
        </>
      ) : (
        <div className="text-primary-v1 text-lg lg:text-2xl font-medium pb-4 lg:pb-8">
          We received your submission
        </div>
      )}

      {/* <div className='flex py-4 flex-col items-start'>
        <div className='py-4'>
          <label htmlFor='name' className='flex flex-col pt-4'>
            Your First name*
            <input
              type='text'
              name='name'
              id='name'
              placeholder='Enter your first name'
              className='min-w-custom-input border p-2'
              required
            />
          </label>
        </div>
        <div className='py-4'>
          <label
            htmlFor='name'
            className='custom-input-width flex flex-col pt-4'
          >
            Your Last name*
            <input
              type='text'
              name='name'
              id='name'
              placeholder='Enter your last name'
              className='min-w-custom-input border p-2'
              required
            />
          </label>
        </div>
        <div className='py-4 min-w-full'>
          <button className='bg-primary-v1 text-white font-bold min-w-full border py-4 px-6'>
            Start Verification
          </button>
        </div>
      </div> */}
      <PoweredBy />
    </section>
  );
};

export default PageBody;
