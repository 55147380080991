const PageHeader = () => {
  return (
    <header className="lg:h-32 min-w-full flex items-center border p-4 lg:px-12">
      <div>
        <a className="flex items-center justify-center demo-logo lg:gap-4">
          <img
            className="h-16 lg:-24"
            src="https://cdn-img.buyproperly.com/company/Logo-alternate.png

"
            alt="bp logo"
          />
          {/* <img
            className='h-16 lg:h-24'
            src='images/Meadowbank-Main-Logo.jpeg'
            alt='Meadowbank Logo'
          /> */}
        </a>
      </div>
      {/* <div className="lg:text-3xl lg:font-medium pl-1 lg:pl-4">
        Meadowbank Asset Management Inc.
      </div> */}
    </header>
  );
};

export default PageHeader;
