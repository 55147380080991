import PageBody from './Body';
import PageHeader from './Header';
import './Home.css';

function Home() {
  return (
    <div className='flex flex-col min-h-screen'>
      <PageHeader />
      <PageBody />
    </div>
  );
}

export default Home;
