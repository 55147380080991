export default function PoweredBy() {
  return (
    <div
      className='flex justify-center items-center py-4'
      style={{ color: '#27272A' }}
    >
      <div className='pr-2'>Powered by</div>
      <img src='images/bp-logo.png' alt='bp logo' />
    </div>
  );
}
